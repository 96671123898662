<template>
  <div class="contenedorPrincipal">
    <BarraNavegacion/>
    <router-view class="routerView" @actualizar-footer="actualizarFooter"/>
    <PieDePagina v-if="!esAdmin" id="contacto"/>
  </div>
</template>
<script>
import BarraNavegacion from "@/components/BarraNavegacion.vue";
import PieDePagina from "@/components/PieDePagina.vue";
import { ref } from "vue";
export default {
  name: "App",
  components: {
    BarraNavegacion,
    PieDePagina
  },
  setup() {
    const mostrarFooter = ref(true);

    // Método para actualizar la visibilidad del footer
    const actualizarFooter = (mostrar) => {
      mostrarFooter.value = mostrar;
    };

    return { mostrarFooter, actualizarFooter };
  },
  props:{
    esNav: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    esAdmin() {
      return (
       this.$route.path === "/iniciar-sesion" ||
        this.$route.path === "/:catchAll(.*)" ||
        this.$route.path.startsWith("/admin")
      );
    },
  },
};
</script>

<style>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
#app {
  font-family: montserrat;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #040101;
  background-color: #F8F8F8;
}

.contenedorPrincipal {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ocupa el 100% de la altura de la ventana */
}

.routerView {
  flex: 1; /* Ocupa el espacio disponible entre el nav y el footer */
}

.displayNone {
  display: none;
}

.swal2-title {
  font-family: montserrat;
  font-size: 1.5em; /* Ajusta el tamaño del título */
  color: #333; /* Cambia el color del texto */
}

.swal2-html-container {
 font-family: montserrat;
  font-size: 1em;
  color: #666;
}

.swal2-confirm,
.swal2-cancel {
  font-family: montserrat;
  font-size: 1em;
  color: #fff;
}

</style>
