<template>
  <div class="contenedor">
    <div class="logo-nav" :class="{ 'logo-nav-admin': esAdmin }">
      <Logo logoWidth="148px" logoHeight="75px" />
      <nav :class="{ displayNone: esAdmin }">
        <router-link to="/">Inicio</router-link>
        <router-link :to="{ path: '/', hash: '#sobre-nosotros' }"
          >Sobre nosotros</router-link
        >
        <router-link to="/catalogo">Catálogo</router-link>
        <router-link to="/nuestros-eventos">Eventos</router-link>
        <router-link :to="{ path: '/', hash: '#contacto' }"
          >Contacto</router-link
        >
      </nav>
    </div>
  </div>
</template>
<script>
import Logo from "@/components/Logo.vue";
export default {
  name: "BarraNavegacion",
  components: {
    Logo,
  },
  
  computed: {
    esAdmin() {
      return (
        this.$route.path === "/iniciar-sesion" ||
        this.$route.path === "/:catchAll(.*)" ||
        this.$route.path.startsWith("/admin")
      );
    },
  },
};
</script>

<style>
.logo-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem;
}

.logo-nav-admin {
  justify-content: center;
  background-color: #ffffff;
}

nav {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 65px;
}

nav a {
  font-family: "Cormorant Upright";
  font-style: normal;
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  color: #000000;
  text-decoration: none;
}

.displayNone {
  display: none;
}
</style>
